import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <footer>
      <h1>CSJ Equipment</h1>
      <div className='contactInfo'>
        <p>6379 Crimson View Drive, South Jordan, UT 84009</p>
        <p>Phone: (801)515-7181</p>
        <p>Email: info@csjequipment.net</p>
      </div>
      <div className='red-triangle'></div>
    </footer>
  );
}

export default Footer;