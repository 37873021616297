import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './masonry.css';



const Masonry= () => {
  const productObj = 
  {title: 'MASONRY',  subTitle: 'We stock our store from vendors all over the country', description:'Check their website to see what we offer', backgroundImage: 'masonryStock.jpg', iconImage: 'masonryIcon.png', toolSubtitle: `Some examples of what we carry in store`,
  tools:[
    'Mortars',
    'Grout',
    'Color',
    'Anchors & Ties',
    'Mixers',
    'Saws',
    'Hand Tools',
  ],
    companies: [
      {name: 'Spec Mix', img: 'specMix.png', description:'Spec Mix is a company that specializes in preblended masonry products, including mortar, grout, and stucco.', link:'https://www.specmix.com/'}, 
      {name: 'Hohman and Benard', img: 'hohmann.png', description:'Manufactures and supplies a wide range of masonry reinforcement and restoration products, including anchors, ties, flashing, and air barriers.', link:'https://www.h-b.com/'},
    ],
    logos: [
      'bon.png', 'conreteColor.png', 'crickTool.png','diamondProducts.png', 'husqvarna.png', 
      'kraftTools.png', 'marshallTown.png', 'mk.jpg', 'multiquip.png', 
      'norton.png', 'stabila.png', 
    ]
  }

  useEffect(() => { 
    AOS.init({duration: 900});
  }, [])
  
  return (
    <div className='mainContainer-veneer'>
      <div className='hero-veneer'>
        <img src={require(`../../../../assets/${productObj.backgroundImage}`)} alt='stucco CoverPhoto'/>
        <div className='iconContainer-veneer'>
          <img style={{objectFit:'cover', width:'200px', height:'230px', right:'-42px', top:'-55px'}} src={require(`../../../../assets/${productObj.iconImage}`)} alt='stucco icon'/>
        </div>
        <h1>{productObj.title}</h1>
      </div>
      <div className='pageDescription-veneer'>
        <h2>{`${productObj.toolSubtitle} for masonry:`}</h2>
        <div data-aos='fade-up' className='toolListVeneer'>
          {productObj.tools.map((tool, i) => {
            return <p key={i}>{tool}</p>
          })}
        </div>
      </div>
      <h2 data-aos='fade-up'>{productObj.description}</h2>
      <div className='companiesContainer-veneer'>
        {productObj.companies.map((company, i) => {
          const isEven = i % 2 === 0;
          const companyContainerClass = isEven ? 'companyContainer-veneer' : 'companyContainerReverse-veneer';
          const companyDescriptionClass = isEven ? 'companyDescription-veneer' : 'companyDescriptionReverse-veneer';
          return (
            <div data-aos='fade-up' className={companyContainerClass}>
              <div className={companyDescriptionClass}>
                <h1>{company.name}</h1>
                <p>{company.description}</p>
                <button>
                  <a href={company.link} target="_blank" rel="noreferrer">
                    View Website
                  </a>
                </button>
              </div>
              <div className={isEven ? 'companyLogoContainer-veneer' : 'companyLogoContainerReverse-veneer'}>
                <img src={require(`../../../../assets/${company.img}`)} alt='company logo' style={{height:`${company.height}`, width:`${company.width}`}}/>
              </div>
            </div>
          )
        })}
  
        <div data-aos='fade-up' className='logo-container-veneer'>
          <h2>Other Vendors That We Work With</h2>
          <section className="logo-grid">
            {productObj.logos.map((logo, index) => (
              <div key={index} className="logo-grid-item">
                <img src={require(`../../../../assets/${logo}`)} alt="Logo" />
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  )
}  

export default Masonry