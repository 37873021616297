import React, {useEffect} from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import './tool.css';



const Tool= () => {
  const productObj = 
  {title: 'TOOLS & EQUIPMENT',  subTitle: 'We stock our store from vendors all over the country', description:'Check their website to see what we offer', backgroundImage: 'equipmentStock.jpeg', iconImage: 'toolIcon.png', toolSubtitle: `Some examples of tools and equipment we carry:`,
   tools:[
    'Safety Equipment',
'EZ Scaffold',
'Mortar Mixers',
'Grout Pumps',
'Mud Tubs',
'High Low Mud Stands',
'Chop Saws',
'Masonry Saws',
   ],

    companies: [
      {name: 'EZG Manufacturing', img: 'ezgManufacturin.png', description:' Designs and manufactures equipment for the construction, masonry, and fencing industries. Their products include mixers, grout pumps, scaffolding, and fencing tools.', link:'https://ezgmfg.com/'}, 
      {name: 'EZ Scaffolding', img: 'ezScaffold.png', description:'EZ Scaffolding offers frames, system, and suspended scaffolds, and also offer related services such as safety inspections and training. ', link:'https://ezscaffold.com/'},
      {name: 'Honda Power', img: 'honda.png', description:'Designs, manufactures, and distributes a wide range of products, including automobiles, motorcycles, power equipment, and aircraft.', link:'https://engines.honda.com/', height: '150px', width:'400px'},
    ],
    logos: [
      'bon.png', 'crickTool.png','diamondProducts.png', 'hilti.png',
      'husqvarna.png', 'kraftTools.png', 'kStrong.png', 'liftAll.jpg',
      'liftSafety.png', 'mikita.png', 'metabo.jpg',
      'marshallTown.png', 'mk.jpg', 'multiquip.png', 
      'norton.png', 'occidental.png', 'primeSource.png', 'pyramex.jpg',
      'stabila.png', 'unitedAbrasives.png', 'usTape.png'
    ]
  }

  useEffect(() => {
    Aos.init({duration: 2000})
  }, [])

  return (
    <div className='mainContainer-veneer'>
      <div className='hero-tools'>
        <img src={require(`../../../assets/${productObj.backgroundImage}`)} alt='stucco CoverPhoto'/>
        <div className='iconContainer-veneer' style={{marginTop: '100px'}}>
          <img style={{left:'3px'}} src={require(`../../../assets/${productObj.iconImage}`)} alt='stucco icon'/>
        </div>
        <h1>{productObj.title}</h1>
      </div>
      <div className='pageDescription-veneer'>
        <h2>{`${productObj.toolSubtitle}`}</h2>
        <div data-aos='fade-up' className='toolListVeneer'>
          {productObj.tools.map((tool, i) => {
            return <p key={i}>{tool}</p>
          })}
        </div>
      </div>
      <h2>{productObj.description}</h2>
      <div className='companiesContainer-veneer'>
        {productObj.companies.map((company, i) => {
          const isEven = i % 2 === 0;
          const companyContainerClass = isEven ? 'companyContainer-veneer' : 'companyContainerReverse-veneer';
          const companyDescriptionClass = isEven ? 'companyDescription-veneer' : 'companyDescriptionReverse-veneer';
          return (
            <div data-aos="fade-up" className={companyContainerClass}>
              <div className={companyDescriptionClass}>
                <h1>{company.name}</h1>
                <p>{company.description}</p>
                <button>
                  <a href={company.link} target="_blank" rel="noreferrer">
                    View Website
                  </a>
                </button>
              </div>
              <div className={isEven ? 'companyLogoContainer-veneer' : 'companyLogoContainerReverse-veneer'}>
                <img src={require(`../../../assets/${company.img}`)} alt='company logo' style={{height:`${company.height}`, width:`${company.width}`}}/>
              </div>
            </div>
          )
        })}
  
        <div data-aos="fade-up" className='logo-container-veneer'>
          <h2>Other Vendors That We Work With</h2>
          <section className="logo-grid">
            {productObj.logos.map((logo, index) => (
              <div key={index} className="logo-grid-item">
                <img src={require(`../../../assets/${logo}`)} alt="Logo" />
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  )
}  

export default Tool