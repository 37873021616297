
import React from "react";
import contactImage from "../../assets/csjLogo.jpg"; // import the contact image
import "./contactPage.css"; 

function ContactPage() {
  return (
    <div className="contact-page">
      <div className="contact-image">
        <img src={contactImage} alt="Contact us" />
      </div>
      <div className="contact-info">
        <h1>Contact Us</h1>
        <p>
          If you have any questions or would like to request more information
          about what we have in Stock, please feel free to contact us.
        </p>
        <p>Phone: (801)515-7181</p>
        <p>Email: info@csjequipment.net</p>
        <p>Address: 6379 Crimson View Drive, South Jordan, UT 84009</p>
      </div>
    </div>
  );
}

export default ContactPage;