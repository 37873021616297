import React, {useEffect, useState} from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import './veneer.css'

const Veneer= (props) => {
  const productObj = 
  {title: 'EXTERIORS',  subTitle: 'We have the resource to get you the tools you need ', toolSubtitle: `Some examples of what we carry in store`, 
  tools :[
    'Stone & Brick Veneer', 'Siding','Stucco' , 'Black Paper', 'House Wrap','Lath Wire','Flashing','Fasteners'
  ], description:`Also check our vendor's website to see what we offer`, backgroundImage: 'veneerStock.jpeg', iconImage: 'veneerIcon.png',  
    companies: [
      {name: 'Quikrete', img: 'quikrete.png', description:'Specializes in the manufacturing and distribution of pre-mixed concrete, mortar, and other building products. Their products are widely used in the construction industry', link:'https://www.quikrete.com/',height:'70%', width:'90%' }, 
      {name: 'Koni Materials', img: 'koniMaterials.png', description:'Koni architectural veneers are practically indistinguishable from natural materials.', link: 'https://www.konimaterials.com/', height:'100px', width:'350px'},
      {name: 'AL13', img: 'al13.png', description:'an innovative cladding company created by installers who were unable to access ACM cladding systems and solutions for their clients', link: 'https://al13.com/our-story/', height:'100px', width:'350px'},
      {name: 'New Tech Wood', img: 'newTechWood.png', description:'New Tech Wood is a composite decking manufacturer offering a variety of composite products', link: 'https://www.newtechwood.com/',  height:'80%', width:'80%'}, 
      {name: 'James Hardie', img: 'jamesHardie.png', description:`James Hardie makes the world’s highest-performance fiber cement siding, trim, backer board and more`, link: 'https://www.jameshardie.com/', height:'60%', width:'120%'},
      {name: 'Henry', img: 'henry.png', description:'Develops integrated air/vapor barrier, roofing and waterproofing systems to ensure superior building performance.', link: 'https://henry.com/',  height:'50%', width:'70%'},
    ],
    logos: [
      'kraftTools.png', 'marshallTown.png', 'sturctaWire.png', 'yorkFlashings 1.png'
    ]
  };


  useEffect(() => { 
      Aos.init({duration: 900})
  }, []);
  

  
  return (
    <div className='mainContainer-veneer'>
      <div className='hero-veneer'>
        <img src={require(`../../../assets/${productObj.backgroundImage}`)} alt='stucco CoverPhoto'/>
        <div className='iconContainer-veneer'>
          <img src={require(`../../../assets/${productObj.iconImage}`)} alt='stucco icon'/>
        </div>
        <h1>{productObj.title}</h1>
      </div>
      <div className='pageDescription-veneer'>
        <h2>{`${productObj.toolSubtitle} for exteriors:`}</h2>
        <div data-aos='fade-up' className='toolListVeneer'>
          {productObj.tools.map((tool, i) => {
            return <p key={i}>{tool}</p>
          })}
        </div>
      </div>
      <h2 data-aos='fade-up'>{productObj.description}</h2>
      <div className='companiesContainer-veneer'>
        {productObj.companies.map((company, i) => {
          const isEven = i % 2 === 0;
          const companyContainerClass = isEven ? 'companyContainer-veneer' : 'companyContainerReverse-veneer';
          const companyDescriptionClass = isEven ? 'companyDescription-veneer' : 'companyDescriptionReverse-veneer';
          return (
            <div data-aos='fade-up' className={companyContainerClass}>
              <div className={companyDescriptionClass}>
                <h1>{company.name}</h1>
                <p>{company.description}</p>
                <button>
                  <a href={company.link} target="_blank" rel="noreferrer">
                    View Website
                  </a>
                </button>
              </div>
              <div className={isEven ? 'companyLogoContainer-veneer' : 'companyLogoContainerReverse-veneer'}>
                <img src={require(`../../../assets/${company.img}`)} alt='company logo' style={{height:`${company.height}`, width:`${company.width}`}}/>
              </div>
            </div>
          )
        })}
  
        <div data-aos='fade-up' className='logo-container-veneer'>
          <h2>Other Vendors That We Work With</h2>
          <section className="logo-grid">
            {productObj.logos.map((logo, index) => (
              <div key={index} className="logo-grid-item">
                <img src={require(`../../../assets/${logo}`)} alt="Logo" />
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  )
}  

export default Veneer