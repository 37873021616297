import React, {useState} from 'react'
import './Header.css'

 const Header = () => {
  
  const [ bannerIsVisible, setBannerIsVisible] = useState(false) 
  
  return (
    <div className='headerContainer'>
       <div className='logoContainer'>
         <a
           href="/products"
           onMouseEnter={() => setBannerIsVisible(true)}
          >Products
         </a>
         <a href="/contact"
         >Contact us</a>
         <div className='companyLogo'>
           <a href='/' >
             <img  
              src={require('../../assets/csjLogo.jpg')} 
              alt="CSJ logo"
              />
           </a>
         </div>
       </div>
       <div className={bannerIsVisible ? 'bannerVisible' : 'bannerInvisible'}
         onMouseLeave={() => setBannerIsVisible(false)}
       >
         <div className='container'>
           <a href="/product/exteriors">
             <div className="logo-container">
               <img 
                src={require("../../assets/veneerIcon.png")} 
                alt="veneer icon" 
                className='veneer-icon'
                />
             </div>
             <p>Exteriors</p>
           </a>
           <a href="/product/masonry">
             <div className="logo-container">
               <img 
                 src={require("../../assets/masonryIcon.png")} 
                 alt="masonry icon" 
                 className='masonry-icon'
                />
             </div>
             <p>Masonry</p>
           </a>
           <a href="/product/concrete">
            <div className="logo-container">
              <img 
               src={require("../../assets/concreteIcon.png")} 
               alt="concrete icon" 
               className='concrete-icon'
               />
            </div>
            <p>Concrete</p>
           </a>
           <a href="/product/tools">
            <div className="logo-container">
              <img 
               src={require("../../assets/toolIcon.png")} 
               alt="tool icon" 
               className='tool-icon'
              />
            </div>
            <p>Tools & Equipment</p>
           </a>
           <a href="/product/services">
            <div className="logo-container">
             <img 
             src={require("../../assets/serviceIcon.png")} 
             alt="service icon" 
             className='service-icon'
             />
            </div>
            <p>Services & Delivery</p>
           </a>
         </div>
       </div>
    </div>
  )
}

export default Header
