import React, {useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './stucco.css';



const Stucco = () => {
  const productObj = 
  {title: 'SERVICE & EQUIPMENT',  subTitle: 'We stock our store from vendors all over the country', backgroundImage: 'csjDelivery.jpg', iconImage: 'serviceIcon.png', toolSubtitle: `Some examples of service we offer`, 
  tools:[
    'EZ Scaffold Service',
    'Authorized Clipper/Norton Service Center',
    'Authorized Honda Service Center',
    'Full Service Delivery'
  ], 
  logos:[
    'husqvarna.png',
  ]
  }

  useEffect(() => {
    Aos.init({duration: 900})
  }, [])
  
  return (
    <div className='mainContainer-veneer'>
      <div className='hero-veneer'>
        <img src={require(`../../../../assets/${productObj.backgroundImage}`)} alt='stucco CoverPhoto'/>
        <div className='iconContainer-veneer' style={{marginTop:'100px'}}>
          <img src={require(`../../../../assets/${productObj.iconImage}`)} alt='stucco icon'/>
        </div>
        <h1>{productObj.title}</h1>
      </div>
      <div className='pageDescription-veneer'>
        <h2>{`${productObj.toolSubtitle}`}</h2>
        <div data-aos='fade-up' className='toolListVeneer'>
          {productObj.tools.map((tool, i) => {
            return <p key={i}>{tool}</p>
          })}
        </div>
      </div>
      <h2>{productObj.description}</h2>
      <div className='companiesContainer-veneer'>
        <div data-aos='fade-up' className='logo-container-veneer'>
          <h2>Other Vendors That We Work With</h2>
          <section className="logo-grid">
            {productObj.logos.map((logo, index) => (
              <div key={index} className="logo-grid-item">
                <img src={require(`../../../../assets/${logo}`)} alt="Logo" />
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  )
}  

export default Stucco