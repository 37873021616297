import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './veneer.css'

const Concrete= () => {
  const productObj = {
    title: 'CONCRETE',
    subTitle: 'We stock our store from vendors all over the country',
    description: 'Check their website to see what we offer',
    toolSubtitle: `Some examples of what we carry in store`,
   tools:[
    'Concrete Blankets',
    'Tie Wire',
    'Concrete Stakes',
    'Fiber Expansion Board',
    'Power Trowels',
    'Vibrators',
    'Hand Tools',
    'Safety Equipment',
   ],
    backgroundImage: 'concreteStock.jpg',
    iconImage: 'concreteIcon.png',
    logos: [
      'bon.png', 'conreteColor.png', 'crickTool.png', 'husqvarna.png',
      'kraftTools.png', 'marshallTown.png', 'multiquip.png', 'mulitvibe.png',
      'stabila.png'
    ]
  }

  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])

  return (
    <div className='mainContainer-veneer'>
      <div className='hero-veneer'>
        <img src={require(`../../../assets/${productObj.backgroundImage}`)} alt='stucco CoverPhoto'/>
        <div className='iconContainer-veneer'>
          <img style={{marginTop: '10px'}} src={require(`../../../assets/${productObj.iconImage}`)} alt='stucco icon'/>
        </div>
        <h1>{productObj.title}</h1>
      </div>
      <div className='pageDescription-veneer'>
        <h2>{`${productObj.toolSubtitle} for concrete:`}</h2>
        <div data-aos='fade-up' className='toolListVeneer'>
          {productObj.tools.map((tool, i) => {
            return <p key={i}>{tool}</p>
          })}
        </div>
      </div>
  
      <div className='companiesContainer-veneer'>
     
  
        <div data-aos="fade-up" className='logo-container-veneer'>
          <h2>Other Vendors That We Work With</h2>
          <section className="logo-grid">
            {productObj.logos.map((logo, index) => (
              <div key={index} className="logo-grid-item">
                <img src={require(`../../../assets/${logo}`)} alt="Logo" />
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  )
}  

export default Concrete
