import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/home/Home.js';
import Header from './shared/Header/Header';
import Footer from './shared/Header/Footer/Footer.js';
import Products from './pages/products/products.js';
import Stucco from './pages/products/product/stucco/stucco.js';
import Veneer from './pages/products/product/veneer.js';
import Concrete from './pages/products/product/concrete.js';
import Masonry from './pages/products/product/masonry/masonry.js';
import Tool from './pages/products/product/tool.js';
import ContactPage from './pages/contact/contactPage.js';
import './App.css';

function App() {

   return (
    <div className="App">
       <Router className="route">
          <Header />
          <Routes>
            <Route path='/' element={<Home />}/> 
            <Route path='/products' element={<Products />}/>
            <Route path={`/product/services`} element={<Stucco />}/>
            <Route path={`/product/exteriors`} element={<Veneer />}/>
            <Route path={`/product/concrete`} element={<Concrete />}/>
            <Route path={`/product/masonry`} element={<Masonry />}/>
            <Route path={`/product/tools`} element={<Tool />}/>
            <Route path='/contact' element={<ContactPage />}/>
          </Routes>
          <Footer />  
       </Router>
    </div>
  );
}

export default App;
