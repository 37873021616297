import React, {useEffect} from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import './products.css'

 const Products = () => {
   const catagories = [ 
    {
    h1: 'Exteriors Equipment', 
    p: 'We offer a wide range of equipment for the exterior of your home. From concrete to masonry, we have the tools to get the job done.',
    img:'quikrete.png',
    link: '/product/exteriors'
    },
    {
      h1: 'Masonry Equipment', 
      p: 'We offer a wide range of equipment for the exterior of your home. From concrete to masonry, we have the tools to get the job done.',
      img:'hohmann.png',
      link: '/product/masonry'
      },
      {
        h1: 'Concrete Equipment', 
        p: 'We offer a wide range of equipment for the exterior of your home. From concrete to masonry, we have the tools to get the job done.',
        img:'conreteColor.png',
        link: '/product/concrete'
        },
        {
          h1: 'Tools & Equipment', 
          p: 'We offer a wide range of equipment for the exterior of your home. From concrete to masonry, we have the tools to get the job done.',
          img:'ezgManufacturin.png',
          link: '/product/tools'
          },
          {
            h1: 'Services', 
            p: 'We offer a wide range of equipment for the exterior of your home. From concrete to masonry, we have the tools to get the job done.',
            img:'husqvarna.png',
            link: '/product/services'
            },
   ]
    useEffect(() => {
      Aos.init({duration: 2000})
        }, [])

  return (
    <div>
  {catagories.map((catagory, index) => {
    return (
      <div data-aos="flip-left" class="product-page">
        <div class="product-image">
        </div>
        <div class="product-info">
          <h1>{catagory.h1}</h1>
          <div className='product-container'>
            <div className='product-description'>
              <p>
                {catagory.p}
                <a href={catagory.link}>{`Check out our ${catagory.h1} page`}</a>
              </p>
            </div>
             <img src={require(`../../assets/${catagory.img}`)} alt={`company-logo-${catagory.img}`}/>
          </div>
        </div>
      </div>
    )
  })}
</div>
  )
}

export default Products