import React, {useEffect, useState} from 'react'
import Aos from 'aos';
import { AiFillLinkedin, AiFillInstagram} from 'react-icons/ai'
import 'aos/dist/aos.css';
import './Home.css'

export default function Home() {

  const [loading, setLoading] = useState(false)

  useEffect(() => { 
    setLoading(true)
    
    Aos.init({duration: 2000})

    setLoading(false)
  }, []);
  
  return (
    <div className='mainHomeDiv'>
      <div className='heroSection'>
        <img src={require('../../assets/homePageStock.jpeg')} alt='construction'/>
        <div className='heroSectionDescricption'>
          <h1>SOLUTION PROVIDERS</h1>
          <p>CSJ Equipment</p>
          <a href='/contact'>
            <button>CONTACT US</button>
          </a>
        </div>
      </div>
      <section className='statsSection'data-aos='fade-up'>
        <div className='shopDescription'>
          <h1>HOW WE CAN HELP YOU</h1>
          <p>At CSJ Equipment, We maintain the biggest stock of tools and Products in our store. Whether it's concrete, masonry, or exterior supplies we have what you need. Check out which vendors we stock from or contact us for questions.</p>
        </div>
        <div className='imagesContainer'>
          <div class="image-grid">
            <div class="image-container">
              <img src={require("../../assets/manger 1.png")} alt='construction draft'/>
              <div class="image-text">Unbeatable Customer Value</div>
              <p>We understand that providing unbeatable customer value is the key to success. That's why we strive to go above and beyond for our clients.</p>
            </div>
            <div class="image-container">
              <img src={require("../../assets/paperStock.jpg")} alt='Manager'/>
              <div class="image-text">Field and Industry Experts</div>
              <p>Our team of skilled professionals brings a wealth of knowledge and experience to every project, ensuring that we can tackle even the most complex challenges with confidence.</p>
            </div>
            <div class="image-container">
              <img src={require("../../assets/stockStock.jpg")} alt='construction project'/>
              <div class="image-text">Robust Collection of Equipment</div>
              <p>With a wide variety of tools, machinery and Products, at our disposal, we are able to take on projects of all sizes and complexities, and complete them efficiently.</p>
            </div>
          </div>          
        </div>
        <a href='/products'>
          <button>Check Out Our Tools</button>
        </a>
      </section>
      <section className="image-with-text" data-aos='fade-up'>
        <div className="csj-team-container">
          <img src={require("../../assets/csjTeam.jpg")} alt="CSJ Team"/>
        </div>
        <div className="text-container">
          <h1>Meet the Team</h1>
          <h4 style={{color: '#BB0000'}}>Let us help you</h4>
          <p>CSJ Equipment is full of hardworking people that do everything they can to help out the community.</p>
          <img src={require("../../assets/csjLogo.jpg")} alt="CSJ Team"/>
          <div className='socialMedia'>
            <a href="https://www.linkedin.com/company/csj-equipment/"><AiFillLinkedin size={60} color='black'/></a>
            <a href="https://www.instagram.com/csjequipmentutah/"><AiFillInstagram size={60} color='black'/></a>
          </div>
        </div>
      </section>
    </div> 
  )
}


